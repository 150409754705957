import React, { useState } from "react";
import { signInWithPopup, GoogleAuthProvider, getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

var config = {
  apiKey: "AIzaSyCHINpTbSWBXNgkHAQfO_Na48U0_JKbmoA",
  authDomain: "login.jointrusty.com",
};

const firebaseApp = initializeApp(config);
const auth = getAuth(firebaseApp);

export const App: React.FC = () => {
  const [profile, setProfile] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    const PARENT_FRAME = document.location.ancestorOrigins[0];
    const PROVIDER = new GoogleAuthProvider();

    function sendResponse(result: any) {
      globalThis.parent.self.postMessage(JSON.stringify(result), PARENT_FRAME);
    }

    globalThis.addEventListener("message", function ({ data }) {
      if (data.initAuth) {
        signInWithPopup(auth, PROVIDER).then(sendResponse).catch(sendResponse);
      }
    });
  }, []);

  return <span />;
};

export default App;
