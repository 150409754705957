import React from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import App from "./App";

export const Providers: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <GoogleOAuthProvider clientId="678457575280-1ctjedpofsld7j4a2q1pa171aim123do.apps.googleusercontent.com">
      {props.children}
    </GoogleOAuthProvider>
  );
};
